<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="auditData === undefined">
      <h4 class="alert-heading">Erro ao carregar dados</h4>
      <div class="alert-body">
        Atividade não encontrado
        <b-link class="alert-link" :to="{ name: 'apps-audit-list' }">
          Lista de atividades
        </b-link>
        for other audits.
      </div>
    </b-alert>

    <template v-if="auditData">
      <div>
        <b-card title="Equipes">
          <b-row>
            <b-col md="6">
              <b-form-group label="Nome" label-for="nameAudits">
                <b-form-input
                  class="mt-1"
                  v-model="auditData.name"
                  label="name"
                  label-for="nameAudits"
                  readonly
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Membros" label-for="membersAudits">
                <b-form-input
                  v-model="auditData.members"
                  class="mt-1 mb-1"
                  id="members"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BCardGroup,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BCardGroup,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
  },

  data() {
    return {
      userData: store.state.user.userData,
    };
  },
  setup() {
    const auditData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}audit/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        auditData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          auditData.value = undefined;
        }
      });

    return {
      auditData,
    };
  },

  methods: {},
};
</script>

<style></style>
